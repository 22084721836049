module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff","Referrer-Policy: same-origin","Content-Security-Policy: frame-ancestors 'self' https://*.apostrophe.com"]},"mergeSecurityHeaders":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46443601-4","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1921ce45192f4582a67f8a06400b2f0b@sentry.io/1419965","environment":"production","enabled":true,"ignoreErrors":["Not rendering React"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.apostrophe.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apostrophe","short_name":"Apostrophe","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/icons/favicon.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"d7e99b139fbca13d3003e4d1e55d474a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8G99ND","defaultDataLayer":{"type":"object","value":{"platform":"gatsby","branchName":"master"}},"dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":false,"includeInDevelopment":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
